import Home from './containers/pages/home';
import { URL_ROOT } from './utils/constants';

const routeList = [
  {
    id: 1,
    title: 'Home',
    path: URL_ROOT(),
    component: Home
  }
];

const routes = [];
routeList.map(route => routes.push({
  ...route
}));

export default routes;
