import React from 'react';

const Loader = props => {
  const { imageStyle, imageSource } = props;

  return (
    <div className="loader-container">
      <img style={imageStyle} src={imageSource} alt="" />
    </div>
  );
};

export default Loader;
