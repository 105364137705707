export function clearLocalStorage(key) {
  return localStorage.removeItem(key);
}

export const setLocalStorage = (keys, value) => window.localStorage.setItem(keys, value);

export const getLocalStorage = keys => window.localStorage.getItem(keys);

export const getInitials = string => {
  const names = string && string.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
};

export const logout = () => {
  const reduxStore = require('../store');
  const actions = require('../store/actions');
  const { store } = reduxStore;
  const { auth } = actions;
  store.dispatch(auth.authActions.logout({ isAuthenticated: false }));
};
