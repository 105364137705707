import fetch from '../../utils/fetch';
import { apiEndpoint } from '../../utils/constants';

const types = ['LOGIN', 'REGISTER', 'FORGOT_PASSWORD'];

const authTypes = {};
for (let i = 0; i < types.length; i += 1) {
  authTypes[`${types[i]}_REQUEST`] = `${types[i]}_REQUEST`;
  authTypes[`${types[i]}_SUCCESS`] = `${types[i]}_SUCCESS`;
  authTypes[`${types[i]}_ERROR`] = `${types[i]}_ERROR`;
}

const actions = (payload = {}, type) => ({ type, payload });

const authActions = {
  requestLogin: props => actions(props, authTypes.LOGIN_REQUEST),
  successLogin: props => actions(props, authTypes.LOGIN_SUCCESS),
  failedLogin: props => actions(props, authTypes.LOGIN_ERROR),
  logout: props => actions(props, 'AUTH_LOGOUT')
};

const loginUser = (data = {}) => fetch(apiEndpoint.LOGIN, 'post', data);
const registerUser = (data = {}) => fetch(apiEndpoint.REGISTER, 'post', data);
const forgetPassword = (data = {}) => fetch(apiEndpoint.FORGET_PASSWORD, 'post', data);

const auth = { authTypes, authActions, loginUser, registerUser, forgetPassword };

export default auth;
