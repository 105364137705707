import React from 'react';
import { getInitials } from '../../../../utils/helpers';

const TopHome = props => {
  const { name } = props;
  const initialName = getInitials(name);
  return (
    <div className="top-home-row">
      <div className="col-top-home">
        <div className="top-home-avatar">
          <span className="initial-name">{initialName}</span>
        </div>
        <div className="top-home-user">{name && <span>{`Welcome, ${name}`}</span>}</div>
        <div className="top-home-desc">
          <span>Manage your info and data to make Bizzy Dashboard work better for you</span>
        </div>
      </div>
    </div>
  );
};

export default TopHome;
