import React, { useEffect, useState } from 'react';
import { Route, Switch, HashRouter } from 'react-router-dom';
import { Layout } from 'antd';
import './styles/global.scss';
import Login from './containers/pages/login';
import { history } from './store';
import { Header, Wrapper } from './components';
import Routes from './routes';
import { getLocalStorage } from './utils/helpers';

const { Content } = Layout;

function App() {
  const [token, setToken] = useState(null);
  useEffect(() => {
    setToken(getLocalStorage('token'));
  }, []);

  const privateRoutes = (
    <React.Fragment>
      <Layout className="layout__content-wrapper">
        <Header />
        <Content className="layout__content">
          <Wrapper>
            {Routes.map(({ path, component }, index) => (
              <Route exact key={index} path={path} component={component} />
            ))}
          </Wrapper>
        </Content>
      </Layout>
    </React.Fragment>
  );

  return (
    <HashRouter history={history}>
      <Switch>
        {!token ? <Route exact path="/" component={Login} /> : privateRoutes}
      </Switch>
    </HashRouter>
  );
}

export default App;
