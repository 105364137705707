import React from 'react';
import { clearLocalStorage } from '../../../utils/helpers';
import { URL_LOGOUT } from '../../../utils/constants';

function Logout() {
  const onLogout = () => {
    clearLocalStorage('token');
    clearLocalStorage('refresh_token');
    clearLocalStorage('user');
  };

  return (
    <a href={URL_LOGOUT()} onClick={onLogout}>
      Logout
    </a>
  );
}

export default Logout;
