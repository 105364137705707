import axios from 'axios';
import { CODE_STATUS, apiEndpoint } from './constants';
import { setLocalStorage, getLocalStorage, logout } from './helpers';

const AXIOS_TIMEOUT = 35000;
axios.defaults.baseURL = `${process.env.REACT_APP_BASE_API}`;
axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
axios.defaults.headers.delete['Content-Type'] = 'application/json';
axios.defaults.responseType = 'json';
axios.defaults.timeout = AXIOS_TIMEOUT;

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;

    if (error.response.status === CODE_STATUS.UNATHORIZED && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers['Authorization'] = token;
            return axios(originalRequest);
          })
          .catch(err => err);
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refresh_token = getLocalStorage('refresh_token');
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.REACT_APP_BASE_URL_SSO}${apiEndpoint.AUTH}/refresh`, { refresh_token })
          .then(res => {
            if (res.data.status === CODE_STATUS.SUCCESS) {
              setLocalStorage('token', res.data.data.token);
              axios.defaults.headers.common['Authorization'] = res.data.data.token;
              originalRequest.headers['Authorization'] = res.data.data.token;
              processQueue(null, res.data.data.token);
              resolve(axios(originalRequest));
            } else {
              logout();
            }
          })
          .catch(err => {
            logout();
            processQueue(err, null);
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

const fetch = (url, method, data) => axios({ method, url, data });

export default fetch;
