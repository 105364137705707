import { createStore, combineReducers, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import reducers from './reducers';
import rootSaga from './sagas';

const history = createBrowserHistory({
  basename: `${process.env.REACT_APP_BASE_ROUTE_PATH}`
});

let reduxStore;
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
let middlewares = [];
if (process.env.NODE_ENV === 'development') {
  middlewares = [sagaMiddleware, routeMiddleware, logger];
  reduxStore = createStore(
    combineReducers({
      ...reducers,
      router: routerReducer
    }),
    applyMiddleware(...middlewares)
  );
} else {
  middlewares = [sagaMiddleware, routeMiddleware];
  reduxStore = createStore(
    combineReducers({
      ...reducers,
      router: routerReducer
    }),
    applyMiddleware(...middlewares)
  );
}

const store = reduxStore;
sagaMiddleware.run(rootSaga);
export { store, history };
