export const apiEndpoint = {
  LOGIN: '/auth/login',
  REFRESH_TOKEN: '/auth/token',
  PROFILE: 'auth/profile',
  FORGET_PASSWORD: '/auth/forgot-password',
  REGISTER: '/auth/register'
};

export const CODE_STATUS = {
  SUCCESS_0: 0,
  SUCCESS: 200,
  BAD_REQUEST: 400,
  INTERNAL_SERVER_ERROR: 500,
  UNATHORIZED: 401,
  FORBIDDEN: 403,
  SERVICE_NOT_FOUND: 404
};

export const MESSAGE_STATUS = {
  SUCCESS: 'Success',
  FAILED: 'Failed',
  ERROR: 'Error'
};

export const Constants = {
  DATE_FORMAT: {
    DDMMYY: 'DD-MM-YYYY',
    DDMMMMYY: 'DD MMMM YYYY',
    YYMMDD: 'YYYY-MM-DD',
    DATE_TIME: 'DD MMMM YYYY, HH:mm',
    YYYY: 'YYYY'
  },
  DATE_VALUE: {
    MINIMUM: '01-01-1950'
  },
  TIME_FORMAT: {
    HHmm: 'HH:mm',
    HHmmss: 'HH:mm:ss',
    HH: 'HH'
  },
  PERMISSION: {
    AUTHORIZED: 'authorized',
    DENIED: 'denied',
    UNDETERMINED: 'undetermined',
    RESTRICTED: 'restricted'
  },
  PAGE: {
    CURRENT: 1,
    PAGE_SIZE: 10,
    LIMIT: 10,
    TOTAL: 0,
    SORT_DIRECTION: 'asc'
  },
  PHONE_PREFIX_ID: '+62'
};

export const URL_ROOT = () => '/';
export const URL_LOGIN = () => '/login';
export const URL_REGISTER = () => '/register';
export const URL_PROFILE = () => '/profile';
export const URL_FORGOT_PASSWORD = () => '/forgot-password';
export const URL_LOGOUT = () => `${process.env.REACT_APP_BASE_ROUTE_PATH}`;
export const URL_MDM = () => `${process.env.REACT_APP_BASE_URL}/mdm${process.env.REACT_APP_BASE_PATH}`;
export const URL_SSO = () => `${process.env.REACT_APP_BASE_URL}/sso${process.env.REACT_APP_BASE_PATH}`;
export const URL_SOMP = () => `${process.env.REACT_APP_BASE_URL}/sds${process.env.REACT_APP_BASE_PATH}`;
export const URL_TMS = () => `${process.env.REACT_APP_BASE_URL}/tms${process.env.REACT_APP_BASE_PATH}`;
export const URL_TMS_SAP = () => `${process.env.REACT_APP_BASE_URL}/tmsdop${process.env.REACT_APP_BASE_PATH}`;
export const URL_IDSP = () => `${process.env.REACT_APP_BASE_URL}/idsp${process.env.REACT_APP_BASE_PATH}`;
export const URL_FCT = () => `${process.env.REACT_APP_BASE_URL}/fct${process.env.REACT_APP_BASE_PATH}`;
export const URL_PMP = () => `${process.env.REACT_APP_BASE_URL}/pmp${process.env.REACT_APP_BASE_PATH}`;
export const URL_CMS = () => `${process.env.REACT_APP_BASE_URL}/cms${process.env.REACT_APP_BASE_PATH}`;
export const URL_CP = () => `${process.env.REACT_APP_BASE_URL}/cp${process.env.REACT_APP_BASE_PATH}`;
