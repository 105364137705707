import React from 'react';
import { connect } from 'react-redux';
import { AppCardList, TopHome } from './component';
import { getLocalStorage } from '../../../utils/helpers';

function Home(props) {
  const { user } = props;
  const profile = (getLocalStorage('user') && JSON.parse(getLocalStorage('user'))) || user;
  return (
    <React.Fragment>
      <div className="page-home">
        <TopHome {...profile} />
        <AppCardList {...profile} />
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  loading: state.authReducer.loading,
  user: state.authReducer.user
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
