import React from 'react';
import { Card } from '../../../../components';
import ssoIcon from '../../../../assets/shortcut/sso-logo.svg';
import mdmIcon from '../../../../assets/shortcut/mdm-logo.svg';
import pmpIcon from '../../../../assets/shortcut/pmp-logo.svg';
import sompIcon from '../../../../assets/shortcut/somp-logo.svg';
import tmsIcon from '../../../../assets/shortcut/tms-logo.svg';
import imsIcon from '../../../../assets/shortcut/ims-logo.svg';
import fctIcon from '../../../../assets/shortcut/fct-logo.svg';
import cmsIcon from '../../../../assets/shortcut/cms-logo.svg';
import cpmIcon from '../../../../assets/shortcut/cpm-logo.svg';
import tmsSapIcon from '../../../../assets/shortcut/tms-sap-logo.svg';

const AppCardList = props => {
  const { permissions } = props;

  return (
    <div className="bottom-home-row">
      {permissions && permissions.length > 0 && permissions.map((item, key) => {
        const appCode = item && item.app && item.app.code;
        item = { ...item, app: { ...item.app, description: 'Manage user info to make Bizzy Connect work better for you', icon: ssoIcon } };
        return (appCode.toLowerCase() === 'sso') && <Card key={key} {...item} />;
      })}
      {permissions && permissions.length > 0 && permissions.map((item, key) => {
        const appCode = item && item.app && item.app.code;
        item = {
          ...item,
          app: { ...item.app, description: 'Empower your decision making and business improvement with relevant data', icon: mdmIcon }
        };
        return (appCode.toLowerCase() === 'mdm') && <Card key={key} {...item} />;
      })}
      {permissions && permissions.length > 0 && permissions.map((item, key) => {
        const appCode = item && item.app && item.app.code;
        item = { ...item, app: { ...item.app, description: 'One stop solution to manage your promo, pricing and combo', icon: pmpIcon } };
        return (appCode.toLowerCase() === 'pmp') && <Card key={key} {...item} />;
      })}
      {permissions && permissions.length > 0 && permissions.map((item, key) => {
        const appCode = item && item.app && item.app.code;
        item = { ...item, app: { ...item.app, description: 'The management and execution of your Sales Order starts here', icon: sompIcon } };
        return (appCode.toLowerCase() === 'sop') && <Card key={key} {...item} />;
      })}
      {permissions && permissions.length > 0 && permissions.map((item, key) => {
        const appCode = item && item.app && item.app.code;
        item = { ...item, app: { ...item.app, description: 'Manage a seamless delivery service for our customers', icon: tmsIcon } };
        return (appCode.toLowerCase() === 'tms-dop') && <Card key={key} {...item} />;
      })}
      {permissions && permissions.length > 0 && permissions.map((item, key) => {
        const appCode = item && item.app && item.app.code;
        item = {
          ...item,
          app: {
            ...item.app, description: 'Everything you need to keep track of your inventory management and distribution', icon: imsIcon
          }
        };
        return (appCode.toLowerCase() === 'idspct') && <Card key={key} {...item} />;
      })}
      {permissions && permissions.length > 0 && permissions.map((item, key) => {
        const appCode = item && item.app && item.app.code;
        item = { ...item, app: { ...item.app, description: 'Gain control of finance and accounting process in one go', icon: fctIcon } };
        return (appCode.toLowerCase() === 'fct') && <Card key={key} {...item} />;
      })}
      {permissions && permissions.length > 0 && permissions.map((item, key) => {
        const appCode = item && item.app && item.app.code;
        item = { ...item, app: { ...item.app, description: 'Customize promo and product display to appeal to more customers', icon: cmsIcon } };
        return (appCode.toLowerCase() === 'cms') && <Card key={key} {...item} />;
      })}
      {permissions && permissions.length > 0 && permissions.map((item, key) => {
        const appCode = item && item.app && item.app.code;
        item = { ...item, app: { ...item.app, description: 'Boost customer engagement through a strategic call plan', icon: cpmIcon } };
        return (appCode.toLowerCase() === 'cp') && <Card key={key} {...item} />;
      })}
      {permissions && permissions.length > 0 && permissions.map((item, key) => {
        const appCode = item && item.app && item.app.code;
        item = {
          ...item,
          app: { ...item.app, description: 'Manage your info and data to make Bizzy Dashboard work better for you.', icon: tmsSapIcon }
        };
        return (appCode.toLowerCase() === 'tms') && <Card key={key} {...item} />;
      })}
    </div>
  );
};

export default AppCardList;
