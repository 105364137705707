import { all, takeEvery, put, fork, call, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { auth } from '../actions';
import { CODE_STATUS, URL_LOGIN, URL_ROOT } from '../../utils/constants';
import { setLocalStorage, clearLocalStorage } from '../../utils/helpers';
import { Notification } from '../../components';

const { authTypes, loginUser } = auth;

export function* loginRequest() {
  const loggedIn = function*({ payload }) {
    try {
      const response = yield call(loginUser, payload);
      if (response.status === CODE_STATUS.SUCCESS) {
        yield put({
          type: authTypes.LOGIN_SUCCESS,
          payload: {
            token: response.data.data.token,
            refresh_token: response.data.data.refresh_token,
            user: response.data.data.user
          }
        });

        setLocalStorage('token', response.data.data.token);
        setLocalStorage('refresh_token', response.data.data.refresh_token);
        window.localStorage.setItem('user', JSON.stringify(response.data.data.user));

        if (response.data && response.data.data.token) {
          yield put(push(URL_ROOT()));
        } else {
          yield put(push(URL_LOGIN()));
        }

        return;
      }

      yield put({
        type: authTypes.LOGIN_ERROR,
        payload: {
          message: response.data.message
        }
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      yield put({
        type: authTypes.LOGIN_ERROR,
        payload: {
          errorAuth: errorResponse
        }
      });

      Notification('error', errorResponse.message || error.message);
    }
  };

  yield takeLatest(authTypes.LOGIN_REQUEST, loggedIn);
}

export function* logout() {
  const loggedOut = function*() {
    clearLocalStorage('token');
    clearLocalStorage('refresh_token');
    clearLocalStorage('user');
    try {
      yield put({
        type: '',
        payload: {
          isAuthenticated: false
        }
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  yield takeEvery('AUTH_LOGOUT', loggedOut);
}

export default function* rootSaga() {
  yield all([fork(loginRequest), fork(logout)]);
}
