import { auth } from '../actions';

const initState = {
  loading: false,
  user: {},
  token: null,
  refresh_token: null,
  isAuthenticated: false
};

const authReducer = (state = initState, { type, payload }) => {
  const { authTypes } = auth;
  switch (type) {
    case authTypes.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        type
      };
    case authTypes.LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        isAuthenticated: true,
        type
      };
    case authTypes.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        ...payload,
        type
      };
    case authTypes.AUTH_LOGOUT:
      return {
        ...initState,
        isAuthenticated: payload.isAuthenticated
      };
    default:
      return state;
  }
};

export default authReducer;
