import React from 'react';
import { Button } from 'antd';
import { URL_MDM, URL_IDSP, URL_SOMP, URL_SSO, URL_TMS, URL_FCT, URL_PMP, URL_CMS, URL_CP, URL_TMS_SAP } from '../../utils/constants';

const Card = props => {
  const { app } = props;
  const openApps = code => {
    switch (code) {
      case 'MDM':
        window.open(URL_MDM(), '_blank');
        break;
      case 'FCT':
        window.open(URL_FCT(), '_blank');
        break;
      case 'IDSPCT':
        window.open(URL_IDSP(), '_blank');
        break;
      case 'TMS':
        window.open(URL_TMS(), '_blank');
        break;
      case 'SSO':
        window.open(URL_SSO(), '_blank');
        break;
      case 'SOP':
        window.open(URL_SOMP(), '_blank');
        break;
      case 'PMP':
        window.open(URL_PMP(), '_blank');
        break;
      case 'CMS':
        window.open(URL_CMS(), '_blank');
        break;
      case 'CP':
        window.open(URL_CP(), '_blank');
        break;
      case 'TMS-DOP':
        window.open(URL_TMS_SAP(), '_blank');
        break;
      default:
        break;
    }
  };

  return (
    <div className="card-component">
      {app && (
      <div className="card-container">
        <div className="left-content">
          <span className="apps-name">
            {app.name}
            <br />
            <p className="apps-desc">{app.description}</p>
          </span>
          <div className="button">
            <Button type="ghost" htmlType="button" onClick={() => openApps(app.code)}>
              Get started
            </Button>
          </div>
        </div>
        <div className="right-content">
          <img src={app.icon} alt="" />
        </div>
      </div>
      )}
    </div>
  );
};

export default Card;
