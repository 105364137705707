/* eslint-disable no-unused-vars */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Layout, Menu, Dropdown } from 'antd';
import { connect } from 'react-redux';
import router from '../../routes';
import bizzyLogo from '../../assets/bizzy-logo-default.svg';
import { getLocalStorage, getInitials } from '../../utils/helpers';
import Logout from '../../containers/pages/logout';

const { Header } = Layout;

// eslint-disable-next-line no-unused-vars
const Headers = ({ onClick, collapsed, user }) => {
  const profile = (Object.keys(user).length > 0 && user) || (getLocalStorage('user') && JSON.parse(getLocalStorage('user')));
  const initialName = profile && getInitials(profile.name);
  const profileMenu = (
    <Menu>
      <Menu.Item>
        <Logout />
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="layout__headers">
      <div className="layout__headers--wrapper">
        <div className="layout__headers--icon">
          <img role="presentation" src={bizzyLogo} alt="" />
        </div>
        <div className="layout__headers--right-menu">
          <Dropdown overlay={profileMenu} trigger={['click']}>
            <div className="isoImgWrapper">
              <span className="initial-name">{initialName}</span>
              <span className="userActivity online" />
            </div>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = state => ({
  loading: state.authReducer.loading,
  user: state.authReducer.user
});

export default connect(mapStateToProps, {})(Headers);
