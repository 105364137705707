import React, { useState, useEffect } from 'react';
import { Form, Icon, Input, Button, Checkbox, Layout, Modal } from 'antd';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { auth } from '../../../store/actions';
import { Header, Wrapper, Loader, Notification } from '../../../components';
// eslint-disable-next-line import/no-cycle
import Routes from '../../../routes';
import bizzyLoader from '../../../assets/bizzy-loader.gif';
import { getLocalStorage, setLocalStorage } from '../../../utils/helpers';
import fetch from '../../../utils/fetch';
import { apiEndpoint } from '../../../utils/constants';

const FormItem = Form.Item;
const { Content } = Layout;

function Login(props) {
  const [showPassword, setShowpassword] = useState(false);
  const [{ username, password }, setState] = useState({ username: '', password: '' });
  const [visible, setVisible] = useState(false);
  const {
    isAuthenticated,
    loading,
    form: { getFieldDecorator }
  } = props;

  useEffect(() => {
    setState({ username: getLocalStorage('username') || '', password: getLocalStorage('password') || '' });
  }, []);

  const viewPassToggle = () => {
    setShowpassword(!showPassword);
  };

  const handleChange = ({ target: { name, value } }) => {
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const onRememberAuth = e => {
    const { form: { setFieldsValue } } = props;
    setState({ username: '', password: '' });
    if (e.target.checked) {
      setState({ username: username || getLocalStorage('username'), password: password || getLocalStorage('password') });
      return setFieldsValue({
        username: username || getLocalStorage('username'),
        password: password || getLocalStorage('password')
      });
    }

    return setFieldsValue({
      username: '',
      password: ''
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const {
      form: { validateFields },
      fetchLogin
    } = props;
    validateFields(() => {
      const payload = {
        username: username.toLowerCase(),
        password
      };

      setLocalStorage('username', username);
      setLocalStorage('password', password);
      fetchLogin(payload);
    });
  };

  const openForgotPasswordForm = () => {
    setVisible(!visible);
  };

  const submitForgotPassword = e => {
    e.preventDefault();
    const {
      form: { validateFields, getFieldValue }
    } = props;
    validateFields(async () => {
      const payload = { username: getFieldValue('email') };
      try {
        const response = await fetch(apiEndpoint.FORGET_PASSWORD, 'post', payload);
        if (response.status === 200) {
          Notification('error', 'Successfully, please check your email inbox');
        }

        setVisible(!visible);
      } catch (error) {
        const errorMessage = (error && error.response && error.response.data && error.response.data.message) || error.message;
        Notification('error', errorMessage);
      }
    });
  };

  const formModal = () => (
    <div>
      <Modal
        visible={visible}
        title="Forgot Password"
        onOk={submitForgotPassword}
        okText="Submit"
        onCancel={() => { setVisible(!visible); }}
      >
        <Form layout="inline" onSubmit={submitForgotPassword}>
          <Form.Item label="Email">
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Please input your email!' }],
            })(
              <Input
                prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Input your email"
              />,
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );

  const view = (
    <div>
      {loading && <Loader imageStyle={{ height: '260px', width: '260px' }} imageSource={bizzyLoader} />}
      <div className="login-wrapper" style={{ backgroundSize: 'auto 100%' }}>
        <div className="left-login-wrapper">
          <div className="login-icon" />
          <div className="login-title">Focus on the work that matters.</div>
          <div className="login-logo" />
        </div>
        <div className="isoLoginContentWrapper">
          <div className="isoLoginContent">
            <div className="isoLogoWrapper">
              <span className="label-sign">Sign In</span>
              {/* <div>
                <span className="label-or">Or</span>
                <span className="label-signup">Request create new account</span>
              </div> */}
            </div>
            <Form onSubmit={handleSubmit} className="form-login">
              <FormItem>
                {getFieldDecorator('username', {
                  rules: !visible && [{ required: true, message: 'Please input your Email!' }]
                })(<Input name="username" onChange={handleChange} placeholder="Username or Email" />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator('password', {
                  rules: !visible && [{ required: true, message: 'Please input your Password!' }]
                })(
                  <Input
                    name="password"
                    onChange={handleChange}
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    addonAfter={<Icon type="eye" onClick={viewPassToggle} style={{ cursor: 'pointer' }} />}
                  />
                )}
              </FormItem>
              <Form.Item>
                <Checkbox onChange={onRememberAuth} style={{ color: '#9c9c9c' }}>Remember me</Checkbox>
              </Form.Item>
              <FormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={username !== '' && password !== '' ? 0 : 1}
                  className="login-form-button"
                >
                  Login
                </Button>
              </FormItem>
              <div>
                <span className="label-forgot-password" onClick={openForgotPasswordForm}>Forgot your password?</span>
              </div>
            </Form>
          </div>
        </div>
      </div>
      {formModal()}
    </div>
  );

  const mainLayout = (
    <React.Fragment>
      <Layout className="layout__content-wrapper">
        <Header {...props} />
        <Content className="layout__content">
          <Wrapper>
            {Routes.map(({ path, component }, index) => (
              <Route exact key={index} path={path} component={component} />
            ))}
          </Wrapper>
        </Content>
      </Layout>
    </React.Fragment>
  );

  return <React.Fragment>{!loading && isAuthenticated ? mainLayout : view}</React.Fragment>;
}

const WrappedNormalLoginForm = Form.create()(Login);

const mapStateToProps = state => ({
  loading: state.authReducer.loading,
  user: state.authReducer.user,
  isAuthenticated: state.authReducer.isAuthenticated
});

const mapDispatchToProps = dispatch => ({
  fetchLogin: data => dispatch(auth.authActions.requestLogin(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(WrappedNormalLoginForm);
